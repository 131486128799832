import { useState } from "react";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Contact() {
    const [submit, setSubmit] = useState(false);

    function handleSubmit() {
        setSubmit(true);
    }
    if (!submit) {
        return (
            <section id="contact">
                <div className="flex px-10 py-5 flex-col items-center text-sm sm:text-md md:text-lg">
                    <div className="flex pb-10">
                      <h1 className="font-bold text-2xl">Contact Me</h1>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex pb-2">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <p>&nbsp;&nbsp;&nbsp;</p>
                        <a href="mailto:andrewgoodingphotography@gmail.com" className="hover:underline">andrewgoodingphotography@gmail.com</a>
                      </div>
                      <div className="flex">
                        <FontAwesomeIcon icon={faInstagram} />
                        <p>&nbsp;&nbsp;&nbsp;</p>
                        <a href="https://www.instagram.com/andrewgoodingphoto/" className="hover:underline">@andrewgoodingphoto</a>
                      </div>
                    </div>
                    <div class="inline-flex items-center justify-center w-full pt-5 pb-5">
                      <hr class="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                      <span class="absolute px-3 text-md md:text-lg text-gray-900 -translate-x-1/2 bg-white left-1/2">or</span>
                    </div>
                    <form name="contact-form" method="POST">
                        <input type="hidden" name="form-name" value="contact-form"/>
                        <div className="pb-5"> 
                            <label>Name: </label>
                            <input 
                              className="border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                              type="text" 
                              name="name" 
                              required 
                            />
                        </div>
                        <div className="pb-5">
                            <label>Email: </label>
                            <input 
                              className="border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                              type="email" 
                              name="email" 
                              required 
                            />
                        </div>
                        <div className="pb-5">
                            <label>Subject: </label>
                            <input 
                              className="border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                              type="text" 
                              name="subject" 
                            />
                        </div>
                        <div className="pb-5">     
                            <label>Message: </label>
                            <textarea 
                              className="border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                              name="message" 
                              required
                            />
                        </div>
                        <div className="flex justify-center">
                          <button 
                            className="button bg-gray-300 hover:bg-gray-700 text-white font-bold py-2 px-4" 
                            type="submit"
                          >
                            Contact
                          </button>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
    return (
        <section id="contact">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <h1>
                    Thank you for getting in touch! I will reply to your query as soon as possible.
                </h1>
            </div>
        </section>
      
    )
}
import aerial from '../assets/site/Home-page-7.png';
import aerialMobile from '../assets/site/Home-page-8.png';
import pets from '../assets/site/Home-page-1.png';
import petsMobile from '../assets/site/Home-page-2.png';
import wildlife from '../assets/site/Home-page-5.png';
import wildlifeMobile from '../assets/site/Home-page-6.png';
import landscape from '../assets/site/Home-page-3.png';
import landscapeMobile from '../assets/site/Home-page-4.png';
import street from '../assets/site/Home-page-9.png';
import streetMobile from '../assets/site/Home-page-10.png'
import { isMobile, useWindowSize } from './Utilities';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Carousel from 'react-multi-carousel';
import { copyrightAlert } from './Utilities';
import "react-multi-carousel/lib/styles.css";


const largeAssets = [
    {name: 'landscape', img: landscape}, 
    {name: 'wildlife', img: wildlife},
    {name: 'aerial', img: aerial}, 
    {name: 'steet', img: street}, 
    {name: 'pets', img: pets}
];
const mobileAssets  = [
    {name: 'landscape', img: landscapeMobile},
    {name: 'wildlife', img: wildlifeMobile}, 
    {name: 'aerial', img: aerialMobile},
    {name: 'street', img: streetMobile},
    {name: 'pets', img: petsMobile}
];

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 10
    }
  };

const CustomRightArrow = ({ onClick }) => {
    return <button onClick={() => onClick()} ><ChevronRightIcon className="absolute right-0 md:right-2 text-white h-6 w-6 opacity-50 hover:opacity-100" /></button>;
};

const CustomLeftArrow = ({ onClick }) => {
    return <button onClick={() => onClick()} ><ChevronLeftIcon className="absolute left-0 md:left-2 text-white h-6 w-6 opacity-50 hover:opacity-100" /></button>;
};

export default function CustomCarousel() {
  const mobile = isMobile(useWindowSize());
  
  const chosenAssets = (mobile ? mobileAssets : largeAssets);
  return (
    <Carousel
      autoPlay={true}
      swipeable={true}
      responsive={responsive}
      centerMode={(mobile ? false : true)}
      itemClass="md:p-0.5"
      infinite
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
    >
      {chosenAssets.map((item) => (
        <div>
          <img src={item.img} alt={item.name} onContextMenu={(e)=> {e.preventDefault(); copyrightAlert()}}/> 
        </div>                    
      ))}
    </Carousel>
  )
}
import React, { useState } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Photography from "./components/Photography";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import { Route, Routes } from "react-router-dom";
import Landscape from "./components/Landscape";
import Wildlife from "./components/Wildlife";
import Aerial from "./components/Aerial";
import Street from "./components/Street";
import Pets from "./components/Pets";
import Footer from "./components/Footer";
import Privacy from "./components/Privacy";
import "yet-another-react-lightbox/styles.css";

export default function App() {
    const [galleryState, setGalleryState] = useState(); 
    
    return (
        <main className="text-black-400 bg-white font-mont">
            <header>
                <Navbar />
            </header>
            <div className="flex justify-center z-0">
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/photography" element={<Photography photos={galleryState} addPhotos={setGalleryState}/>}/>
                    <Route path="/photography/landscape" element={<Landscape photos={galleryState} addPhotos={setGalleryState}/>}/>
                    <Route path="/photography/wildlife" element={<Wildlife photos={galleryState} addPhotos={setGalleryState}/>}/>
                    <Route path="/photography/aerial" element={<Aerial photos={galleryState} addPhotos={setGalleryState}/>}/>
                    <Route path="/photography/street" element={<Street photos={galleryState} addPhotos={setGalleryState}/>}/>
                    <Route path="/photography/pets" element={<Pets photos={galleryState} addPhotos={setGalleryState}/>}/>
                    <Route path="/blog" element={<Blog />}/>
                    <Route path="/contact" element={<Contact />}/>
                    <Route path="/privacy" element={<Privacy />}/>
                </Routes>
            </div>
            <footer>
                <Footer />
            </footer>
        </main>
    )
}
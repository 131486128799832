import { useState, useEffect, useRef } from 'react';
import PhotoAlbum from 'react-photo-album';
import imageProperties from '../assets/image_properties.json';
import Lightbox from "yet-another-react-lightbox";
import { useLocation } from 'react-router-dom';

const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => { ref.current = value })
  
    return ref.current
  }
  
const useLocationChange = (action) => {
    const location = useLocation()
    const prevLocation = usePrevious(location)
    useEffect(() => { 
      action(location, prevLocation) 
    }, [location])
}

// Gallery
const genreMap = {
  "landscape": require.context('../assets/landscape', false, /\.(jpg)$/),
  "wildlife": require.context('../assets/wildlife', false, /\.(jpg)$/),
  "aerial": require.context('../assets/aerial', false, /\.(jpg)$/),
  "street": require.context('../assets/street', false, /\.(jpg)$/),
  "pets": require.context('../assets/pets', false, /\.(jpg)$/),
  "all": require.context('../assets', true, /^(?!.*(?:site)).*\.jpg$/) // Excluded 'site' assets
}

function importAll(r) {
  return r.keys().map(r);
}

function getProperties(image) {
  const re = /\/[^\/\.]+\./

  var fileName = re.exec(image);
  fileName = fileName[0].slice(1) + "jpg";
  var properties = imageProperties[fileName]

  return properties
}

function preparePhotos(genre) {
  const imageList = importAll(genreMap[genre]);

  const photoCount = imageList.length;

  const photos = [];

  for (var i = 0; i < photoCount; i++) {
    const image = imageList[i]

    const size = getProperties(image);
        
    const photo = {
      src: imageList[i],
      width: size.width,
      height: size.height
    };

    photos.push(photo);
  }

  return shuffle(photos)
}

function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default function CustomGallery(props) {
  const [index, setIndex] = useState(-1);

  useLocationChange((location, prevLocation) => { if (location !== prevLocation) 
    {props.addPhotos(preparePhotos(props.genre))}; 
  })

  return(
    <section id={props.genre} className="flex flex-col">
      <PhotoAlbum photos={props.photos} layout="rows" spacing={ 4 } onClick={({ index }) => setIndex(index)}/>
      <Lightbox
        slides={props.photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
      <img src="/water-mark-black.png" alt="watermark" className="pt-10 flex self-center w-20" />
    </section>
  )
}
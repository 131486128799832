import React from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { useMatch, useResolvedPath, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const navigation = [
  { name: "HOME", to: "/", end: true },
  { name: "PORTFOLIO", to: "/photography", end: false },
  { name: "BLOG", to: "/blog", end: false },
  { name: "CONTACT", to: "/contact", end: false }
];

const photographyNavigation = [
  { name: "Landscape", to: "/photography/landscape", end: false},
  { name: "Wildlife", to: "/photography/wildlife", end: false },
  { name: "Aerial", to: "/photography/aerial", end: false },
  { name: "Street", to: "/photography/street", end: false },
  { name: "Pets", to: "photography/pets", end: false },
  { name: "All", to: "photography", end: true }
]

function CustomMenuButton ({children, ...props}) {
  const resolvedPath = useResolvedPath('/photography')
  const isActive = useMatch({ path: resolvedPath.pathname, end: false })

  return (
    <Menu.Button className={isActive ? "flex md:inline-flex p-4 items-center hover:bg-gray-100" : "flex md:inline-flex p-4 items-center hover:bg-gray-100"} {...props}>{children}</Menu.Button>
  )
}

const navLinkClassNames = {
  "default" : "flex md:inline-flex p-4 lg:pl-8 lg:pr-8 items-center hover:bg-gray-100 w-full justify-center",
  "mobile" : "flex md:inline-flex p-4 items-center hover:bg-gray-100 w-full justify-center mobile",
  "mobile-sub-menu" : "flex md:inline-flex p-4 items-center hover:text-black hover:bg-gray-100 bg-gray-800 text-white w-full justify-center mobile"
}

function CustomNavLink ({ to, children, linkType="default", ...props }) {
  const classNames = navLinkClassNames[linkType]

  return (
    <NavLink to={to} className={classNames} {...props}>
      {children}
    </NavLink>
  )
}
  
export default function Navbar() {
    return (
      <Disclosure as="nav" className="flex justify-center bg-white font-mont">
        {({ open }) => (
          <div className="flex flex-grow flex-col">
            <div className="flex flex-grow justify-center max-w-7xl px-2 md:px-6 lg:px-8">
              <div className="relative flex flex-grow justify-center items-center h-14 md:h-20" id="navbar">
                <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button id="disclosureButton" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex items-center justify-center md:justify-start md:flex-grow">
                  <div className="flex justify-center flex-col items-center p-4">
                    <div className="pb-0">
                      <h1 className="tracking-widest text-xl md:text-2xl text-black mb-0 leading-none">
                        ANDREW <strong>GOODING</strong>
                      </h1>
                    </div>
                    <div className="flex justify-center pt-0 mt-0">
                      <h2 className="text-xs md:text-sm tracking-widest">p &nbsp;&nbsp;h &nbsp;&nbsp;o &nbsp;&nbsp;t &nbsp;&nbsp;o &nbsp;&nbsp;g &nbsp;&nbsp;r &nbsp;&nbsp;a &nbsp;&nbsp;p &nbsp;&nbsp;h &nbsp;&nbsp;y</h2>
                    </div>
                  </div>
                  <div className="md:flex hidden">
                    <div className="flex border-l border-gray-500 h-12 self-center"></div>
                    <ul className="flex items-center pl-4 pr-8">
                      <li>
                        <CustomNavLink to="/" end>HOME</CustomNavLink>
                      </li>
                      <li>   
                        <Menu as="div" className="relative">
                          <div className="flex">
                            <CustomMenuButton id="portfolioMenu" onMouseEnter={({target})=> target.click()}>PORTFOLIO</CustomMenuButton>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute left-0 mt-2 w-36 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50" onMouseLeave={()=> document.getElementById("portfolioMenu").click()}>
                              {photographyNavigation.map((item) => (
                                <Menu.Item>
                                  <CustomNavLink to={ item.to } end={ item.end }>{ item.name }</CustomNavLink>
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </li>
                      <li>
                        <CustomNavLink to="/blog">BLOG</CustomNavLink>
                      </li>
                      <li>
                        <CustomNavLink to="/contact">CONTACT</CustomNavLink>
                      </li>
                    </ul>
                    <div className="flex justify-right items-center absolute inset-y-0 right-4">
                      <a href="https://www.instagram.com/andrewgoodingphoto/"><FontAwesomeIcon icon={faInstagram}/></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <Disclosure.Panel className="flex md:hidden">
            <div className="flex flex-col flex-grow pt-2 pb-3 space-y-1">
              {navigation.map((item) => ((item.name !== "PORTFOLIO" ? 
                <CustomNavLink to={ item.to } end={ item.end } linkType={ "mobile" } onClick={()=> document.getElementById("disclosureMenu").click()}>{ item.name }</CustomNavLink> :
                <Disclosure>
                  <Disclosure.Button className="flex md:inline-flex p-4 items-center hover:bg-gray-100 w-full justify-center">{ item.name }</Disclosure.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease duration-200 transform"
                      enterFrom="opacity-0 -translate-y-10"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease duration-100 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-10"
                    >
                      <Disclosure.Panel>
                        {photographyNavigation.map((item) =>
                          <CustomNavLink to={ item.to } end={ item.end } linkType={ "mobile-sub-menu" } onClick={()=> document.getElementById("disclosureMenu").click()}>{ item.name }</CustomNavLink>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                </Disclosure>
              )))}
              <div className="flex justify-center items-center pt-3">
                <a href="https://www.instagram.com/andrewgoodingphoto/"><FontAwesomeIcon icon={faInstagram}/></a>
              </div>
            </div>
        </Disclosure.Panel>
        </div>
        )}
      </Disclosure>
    );
  }
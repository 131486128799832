import headshot from '../assets/site/8G4A1455.jpg';
import CustomCarousel from './CustomCarousel';
import { copyrightAlert } from './Utilities';

export default function Home() {
  return(
    <div className='flex flex-col justify-center'>
      <div className='w-screen'>
        <CustomCarousel />
      </div>
      <div className='flex flex-col sm:flex-row max-w-6xl self-center pt-10 w-4/5'>
        <div className='flex flex-col justify-center items-center text-center sm:w-2/3'>
          <h1 className='text-xl tracking-wider font-extrabold uppercase'>From Cornwall to Canada</h1>
          <br/>
          <p className='text-lg'>Andrew Gooding is a photographer originally from Cornwall, U.K. now residing in Kelowna, British Columbia. His work covers
            various genres, mainly focussing on the themes of travel and adventure. All images from his portfolio are available as prints upon request. 
          </p>
        </div>
        <div className='flex sm:justify-end items-center justify-center sm:w-1/3 pt-10 sm:pt-0'>
          <img src={headshot} alt="headshot" width="80%" className='rounded-full' onContextMenu={(e)=> {e.preventDefault(); copyrightAlert()}}/>
        </div>
      </div>
      <div className='flex justify-center pt-10 sm:pt-20'>
        <a className='button bg-gray-300 hover:bg-gray-700 text-white font-bold py-2 px-4' href="/contact">Work with me</a>
      </div>
    </div>
  )
}

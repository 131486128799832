export default function Privacy() {
  return (
    <div className="flex px-10 py-5 flex-col items-center text-sm sm:text-md md:text-lg">
      <div className="flex pb-10">
        <h1 className="font-bold text-2xl">Privacy Policy</h1>
      </div>
      <div>
        <h1 className="font-bold text-xl pb-5">What personal data we collect and why we collect it</h1>
        <ul>
          <li><h2 className="font-bold text-lg pb-3">Contact Form</h2></li>
        </ul>
        <p>If you fill out the contact form, we collect only the information you provided to us to better understand your 
          photography needs. We may keep this data up to one year after your submission, just in case you have any questions 
          about your final deliverables or we need to reach you. The form also collects your IP address and browser user agent 
          string only to help spam detection.
        </p>
        <h1 className="font-bold text-xl pt-5 pb-5">Who we share your data with</h1>
        <p>We will never share your data with anyone.</p>
        <h1 className="font-bold text-xl pt-5 pb-5">What rights you have over your data</h1>
        <p>You can request that we erase any personal data we hold about you at any time.</p>
        <h1 className="font-bold text-xl pt-5 pb-5">Links to Other Websites</h1>
        <p>This website may contain links to other websites that are not operated by us. If you click on a third party link, 
          you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
          <br></br>
          We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites 
          or services.
        </p>
        <h1 className="font-bold text-xl pt-5 pb-5">Any questions?</h1>
        <p>
          If you have any questions about our privacy policy, email <a href="mailto:andrewgoodingphotography@gmail.com" className="hover:underline">andrewgoodingphotography@gmail.com</a>.
        </p>
      </div>
    </div>
  )
}
import { useState, useEffect } from "react";

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

function getNavbarHeight(windowSize) {
    const navbarHeight = ((windowSize.width < 780) ? 56 : 80)
  
    return navbarHeight
  }

export function windowPercentage(windowSize) {
    const navbarHeight = getNavbarHeight(windowSize);
    const targetHeight = windowSize.height - navbarHeight;
    const photoAspect = 1.5;
    const targetWidth = targetHeight*photoAspect;
    const percentageWidth = (targetWidth/windowSize.width)*100;

    return Math.min(percentageWidth, 80);
}

export function isMobile(windowSize) {
    const mobile = ((windowSize.width < 780) ? true : false);

    return mobile
}

export const copyrightAlert = () => {alert("Copyrighted image")};
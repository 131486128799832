import logo from "../assets/site/Logo.png"
import { ReactTinyLink } from "react-tiny-link"
import { blogPosts } from "../data"

export default function Blog() {
  return(
    <section id="blog">
      <div className="flex px-4 md:px-10 py-5 md:py-20 flex-col items-center w-full">
        <div className="flex flex-col md:items-start md:mb-16 items-center justify-center">
          <a href="https://pastiestopoutine.com/" className="border-none">
            <img className="border-none" width="150" height="150" alt="Pasties To Poutine" src={ logo }></img>
          </a>
        </div>
        <div className="flex text-center">
          <h1 className="site-title text-3xl md:text-4xl font-josefin ">
            <a href="https://pastiestopoutine.com/">Pasties To Poutine</a>
          </h1>
        </div>
        <div className="flex pt-5">
          <p className="mb-8 leading-relaxed">
            Check out our blog for stories from the road. Latest post:
          </p>          
        </div>
        <ReactTinyLink cardSize="large" showGraphic={true} maxLine={2} minLine={1} url={ blogPosts[blogPosts.length-1] } proxyUrl=""/>
      </div>
    </section>
  )
}
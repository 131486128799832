export default function Footer() {
  return(
    <div className="flex flex-col text-xs sm:text-md ">
      <div className="flex text-center justify-center pt-16">
        <h2>Andrew Gooding Photography © 2024 | All Rights Reserved</h2>
      </div>
      <div className="flex justify-center pt-5 pb-10">    
        <a href="/privacy" className="hover:underline">Privacy Policy</a>
      </div>
    </div>
  )
}